import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityState, communityFeatureKey } from './community.reducer';

const selectCommunitiesFeature = createFeatureSelector<{
    [communityFeatureKey]: CommunityState;
}>('communities');

export const selectCommunityDocuments = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].documentList,
);

export const selectCommunityMediaAttachments = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].mediaList,
);

export const selectCommunityMixedAttachments = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].mixedList,
);

export const selectRecentCommunities = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].recentCommunities,
);

export const selectAdvancedCommunityResearchOpened = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].showAdvancedCommunityResearch,
);

export const selectAdminV2TabOpened = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].showAdminV2Tab,
);

export const selectQuickFilters = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].quickFilters,
);

export const selectActiveQuickFilter = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].activeQuickFilters,
);

export const selectInitializedQuickFilters = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].initializedQuickFilters,
);

export const selectPreviousActiveQuickFilter = createSelector(
    selectCommunitiesFeature,
    (state) => state[communityFeatureKey].previousActiveQuickFilter,
);
