import { isDefined, mapArrayById, PageTokenInfo } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import { HomeCommunityIndex } from '@modules/core/models/utility.model';
import * as fromAppStore from '@modules/core/store';
import {
    AttachmentCategoryType,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    attachmentsListFilter,
    emptyAttachmentsList,
    isFileAttachment,
    isMediaAttachment,
    updateAttachmentsList,
} from '@modules/post/models/attachment/attachment.utils';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    QuickFilter,
    QuickFiltersList,
} from '@modules/post/models/quick-filters/quick-filters.model';
import {
    getDefaultQuickFiltersList,
    getSystemQuickFilters,
} from '@modules/post/models/quick-filters/quick-filters.util';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import * as PostApiActions from '../post/post-api.actions';
import * as CommunityApiActions from './community-api.actions';
import * as CommunityActions from './community.actions';

export interface CommunityLoadingInfo {
    isFetching: boolean;
    pageTokenInfo: PageTokenInfo;
}

export interface CommunityState {
    communityId: number | null;
    mediaList: IListAttachments;
    documentList: IListAttachments;
    mixedList: IListAttachments;
    recentCommunities: ICommunity[];
    quickFilters: QuickFiltersList | null;
    activeQuickFilters: Record<HomeCommunityIndex, QuickFilter | null>;
    initializedQuickFilters: Record<number, Partial<IPostFilters>>;
    showAdvancedCommunityResearch: boolean;
    showAdminV2Tab: boolean;
    previousActiveQuickFilter: {
        id: HomeCommunityIndex;
        quickFilter: QuickFilter;
    } | null;
}

export const communityFeatureKey = 'community';

export interface CommunityFeatureState extends fromAppStore.AppState {
    [communityFeatureKey]: CommunityState;
}

const initialState: CommunityState = {
    communityId: null,
    documentList: emptyAttachmentsList(AttachmentCategoryType.DOCUMENT),
    mediaList: emptyAttachmentsList(AttachmentCategoryType.MULTIMEDIA),
    mixedList: emptyAttachmentsList(AttachmentCategoryType.MIXED),
    recentCommunities: [],
    quickFilters: null,
    activeQuickFilters: {
        dashboard: null,
    },
    initializedQuickFilters: {},
    showAdvancedCommunityResearch: false,
    showAdminV2Tab: false,

    previousActiveQuickFilter: null,
};

export const communityReducer = createReducer(
    initialState,

    on(CommunityActions.fetchAttachments, (state, props) => ({
        ...state,
        communityId: props.communityId,
        mediaList:
            props.attachmentType === AttachmentCategoryType.MULTIMEDIA &&
            state.communityId === props.communityId
                ? {
                      ...state.mediaList,
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.mediaList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.MULTIMEDIA,
                  }
                : {
                      ...emptyAttachmentsList(),
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.mediaList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.MULTIMEDIA,
                  },
        documentList:
            props.attachmentType === AttachmentCategoryType.DOCUMENT &&
            state.communityId === props.communityId
                ? {
                      ...state.documentList,
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.documentList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.DOCUMENT,
                  }
                : {
                      ...emptyAttachmentsList(),
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.documentList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.DOCUMENT,
                  },
        mixedList:
            props.attachmentType === AttachmentCategoryType.MIXED &&
            state.communityId === props.communityId
                ? {
                      ...state.mixedList,
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.mixedList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.MIXED,
                  }
                : {
                      ...emptyAttachmentsList(),
                      isLoading: true,
                      totalCount:
                          state.communityId !== props.communityId
                              ? 0
                              : state.mixedList.totalCount,
                      attachmentCategoryType: AttachmentCategoryType.MIXED,
                  },
    })),

    on(CommunityActions.fetchAttachmentsPage, (state, props) => ({
        ...state,
        communityId: props.communityId,
        mediaList:
            props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                ? { ...state.mediaList, isLoading: true }
                : state.mediaList,
        documentList:
            props.attachmentType === AttachmentCategoryType.DOCUMENT
                ? { ...state.documentList, isLoading: true }
                : state.documentList,
        mixedList:
            props.attachmentType === AttachmentCategoryType.MIXED
                ? { ...state.mixedList, isLoading: true }
                : state.mixedList,
    })),

    on(
        CommunityApiActions.fetchAttachmentsError,
        CommunityApiActions.fetchAttachmentsPageError,
        (state, props) => ({
            ...state,
            mediaList:
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                    ? { ...state.mediaList, isLoading: false }
                    : state.mediaList,
            documentList:
                props.attachmentType === AttachmentCategoryType.DOCUMENT
                    ? { ...state.documentList, isLoading: false }
                    : state.documentList,
            mixedList:
                props.attachmentType === AttachmentCategoryType.MIXED
                    ? { ...state.mixedList, isLoading: false }
                    : state.mixedList,
        }),
    ),

    on(CommunityApiActions.fetchAttachmentsSuccess, (state, props) => {
        const updateAttachmentsList = (
            currAttachmentList: IListAttachments,
        ): IListAttachments => ({
            ...currAttachmentList,
            attachments: props.attachmentsList.attachments,
            pageTokenInfo: props.attachmentsList.pageTokenInfo,
            totalCount: isDefined(props.attachmentsList.totalCount)
                ? props.attachmentsList.totalCount
                : currAttachmentList.totalCount,
            isLoading: false,
        });
        return {
            ...state,
            mediaList:
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                    ? updateAttachmentsList(state.mediaList)
                    : {
                          ...state.mediaList,
                          isLoading: false,
                          totalCount: props.mediasCount ?? 0,
                      },
            documentList:
                props.attachmentType === AttachmentCategoryType.DOCUMENT
                    ? updateAttachmentsList(state.documentList)
                    : {
                          ...state.documentList,
                          isLoading: false,
                          totalCount: props.documentsCount ?? 0,
                      },
            mixedList:
                props.attachmentType === AttachmentCategoryType.MIXED
                    ? updateAttachmentsList(state.mixedList)
                    : {
                          ...state.mixedList,
                          isLoading: false,
                          totalCount:
                              (props.mediasCount ?? 0) +
                              (props.documentsCount ?? 0),
                      },
        };
    }),

    on(CommunityApiActions.fetchAttachmentsPageSuccess, (state, props) => {
        const updateAttachmentsList = (
            currAttachmentList: IListAttachments,
        ): IListAttachments => ({
            ...currAttachmentList,
            attachments: [
                ...currAttachmentList.attachments,
                ...props.attachmentsList.attachments,
            ],
            pageTokenInfo: props.attachmentsList.pageTokenInfo,
            isLoading: false,
        });
        return {
            ...state,
            mediaList:
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                    ? updateAttachmentsList(state.mediaList)
                    : state.mediaList,
            documentList:
                props.attachmentType === AttachmentCategoryType.DOCUMENT
                    ? updateAttachmentsList(state.documentList)
                    : state.documentList,
            mixedList:
                props.attachmentType === AttachmentCategoryType.MIXED
                    ? updateAttachmentsList(state.mixedList)
                    : state.mixedList,
        };
    }),

    on(CommunityApiActions.deleteAttachmentsSuccess, (state, props) => {
        const deletedIdsSet = new Set(props.ids);
        return {
            ...state,
            mediaList: attachmentsListFilter(state.mediaList, deletedIdsSet),
            documentList: attachmentsListFilter(
                state.documentList,
                deletedIdsSet,
            ),
            mixedList: attachmentsListFilter(state.mixedList, deletedIdsSet),
        };
    }),

    on(CommunityApiActions.getRecentCommunitiesSuccess, (state, props) => {
        return {
            ...state,
            recentCommunities: props.communities,
        };
    }),

    on(PostApiActions.changeFiltersSuccess, (state, props) => {
        return {
            ...state,
            mediaList: initialState.mediaList,
            documentList: initialState.documentList,
            mixedList: initialState.mixedList,
        };
    }),

    on(PostApiActions.attachmentsEditSuccess, (state, props) => {
        const updatedMixed = props.attachmentsEdit.updateAttachments;

        const updatedMedia = updatedMixed.filter((a) => isMediaAttachment(a));
        const updatedDocs = updatedMixed.filter((a) => isFileAttachment(a));

        return {
            ...state,
            mediaList: state.mediaList
                ? updateAttachmentsList(state.mediaList, updatedMedia)
                : state.mediaList,
            documentList: state.documentList
                ? updateAttachmentsList(state.documentList, updatedDocs)
                : state.documentList,
            mixedList: state.mixedList
                ? updateAttachmentsList(state.mixedList, updatedMixed)
                : state.mixedList,
        };
    }),

    on(CommunityApiActions.massiveUploadHashtagsSuccess, (state, props) => {
        const updatedAttachmentsMap = idArraytoMap(props.updatedAttachments);
        return {
            ...state,
            mediaList: {
                ...state.mediaList,
                attachments: (state.mediaList.attachments || []).map((a) => {
                    const found = updatedAttachmentsMap[a.id];
                    return found ? { ...a, hashtags: found.hashtags } : a;
                }),
            },
            documentList: {
                ...state.documentList,
                attachments: (state.documentList.attachments || []).map((a) => {
                    const found = updatedAttachmentsMap[a.id];
                    return found ? { ...a, hashtags: found.hashtags } : a;
                }),
            },
            mixedList: {
                ...state.mixedList,
                attachments: (state.mixedList.attachments || []).map((a) => {
                    const found = updatedAttachmentsMap[a.id];
                    return found ? { ...a, hashtags: found.hashtags } : a;
                }),
            },
        };
    }),

    on(CommunityActions.toggleAdvancedCommunityResearch, (state) => {
        return {
            ...state,
            showAdvancedCommunityResearch: !state.showAdvancedCommunityResearch,
        };
    }),

    on(CommunityActions.toggleAdminV2Tab, (state) => {
        return {
            ...state,
            showAdminV2Tab: !state.showAdminV2Tab,
        };
    }),

    on(CommunityApiActions.fetchQuickFiltersSuccess, (state, props) => {
        return {
            ...state,
            quickFilters: props.quickFilters,
        };
    }),

    on(CommunityActions.setActiveQuickFilter, (state, props) => {
        const quickFilter = state.activeQuickFilters[props.communityId];
        return {
            ...state,
            previousActiveQuickFilter:
                props.clearPreviousFilter || !props.quickFilter
                    ? null
                    : quickFilter
                      ? {
                            ...state.previousActiveQuickFilter,
                            id: props.communityId,
                            quickFilter,
                        }
                      : null,
            activeQuickFilters: {
                ...state.activeQuickFilters,
                [props.communityId]: props.quickFilter,
            },
        };
    }),

    on(CommunityActions.resetActiveQuickFilter, (state, props) => {
        const quickFilter = state.activeQuickFilters[props.communityId];
        return {
            ...state,
            previousActiveQuickFilter: quickFilter
                ? {
                      ...state.previousActiveQuickFilter,
                      id: props.communityId,
                      quickFilter,
                  }
                : null,
            activeQuickFilters: {
                ...state.activeQuickFilters,
                [props.communityId]: null,
            },
        };
    }),

    on(CommunityActions.setInitializedQuickFilters, (state, props) => {
        return {
            ...state,
            initializedQuickFilters: {
                ...state.initializedQuickFilters,
                [props.quickFilter.id]: props.quickFilter.filters,
            },
            quickFilters:
                state.quickFilters && state.quickFilters[props.communityId]
                    ? {
                          ...state.quickFilters,
                          [props.communityId]: state.quickFilters[
                              props.communityId
                          ].map((qf) =>
                              qf.id === props.quickFilter.id
                                  ? {
                                        ...qf,
                                        filters: props.quickFilter.filters,
                                    }
                                  : qf,
                          ),
                      }
                    : state.quickFilters,
        };
    }),

    on(CommunityApiActions.createCustomFilterSuccess, (state, props) => {
        return {
            ...state,
            quickFilters: {
                ...(state.quickFilters ?? getDefaultQuickFiltersList()),
                [props.communityId]: [
                    ...(state.quickFilters?.[props.communityId] ||
                        getSystemQuickFilters()),
                    props.quickFilter,
                ],
            },
        };
    }),

    on(CommunityApiActions.deleteCustomFilterSuccess, (state, props) => {
        const updatedFilterIds: number[] = (mapArrayById(props.filters) || [])
            .filter((id) => typeof id === 'number')
            .map((id) => id as number);
        const initialized = state.initializedQuickFilters;
        updatedFilterIds.forEach((id) => delete initialized[id]);

        return {
            ...state,
            quickFilters: {
                ...(state.quickFilters ?? getDefaultQuickFiltersList()),
                [props.homeCommunityIndex]: props.filters,
            },
            initializedQuickFilters: initialized,
        };
    }),

    on(CommunityApiActions.actualizeQuickFilterSuccess, (state, props) => {
        const currList = {
            ...(state.quickFilters ?? getDefaultQuickFiltersList()),
        };
        const filters = currList[props.homeCommunityIndex].map((f) =>
            f.id === props.quickFilter.id
                ? {
                      ...f,
                      filters: props.quickFilter.filters,
                  }
                : f,
        );
        return {
            ...state,
            initializedQuickFilters: {
                ...state.initializedQuickFilters,
                [props.quickFilter.id]: props.quickFilter.filters,
            },
            quickFilters: {
                ...currList,
                [props.homeCommunityIndex]: [...filters],
            },
        };
    }),

    on(CommunityApiActions.modifyCustomFilterSuccess, (state, props) =>
        produce(state, (draft: CommunityState) => {
            const updateFilterIdx = state.quickFilters?.[
                props.homeCommunityIndex
            ].findIndex((f) => f.id === props.filter.id);
            if (
                updateFilterIdx != null &&
                updateFilterIdx > -1 &&
                draft.quickFilters
            ) {
                draft.quickFilters[props.homeCommunityIndex][updateFilterIdx] =
                    props.filter;
                if (
                    typeof props.filter.id === 'number' &&
                    state.initializedQuickFilters[props.filter.id]
                ) {
                    delete draft.initializedQuickFilters[props.filter.id];
                }
            }
        }),
    ),

    on(CommunityActions.changeQuickFiltersOrder, (state, props) => {
        const actualList = (state.quickFilters ?? getDefaultQuickFiltersList())[
            props.homeCommunityIndex
        ];

        const updatedList = props.quickFilters.map((ol) => ({
            ...(actualList.find((al) => al.id === ol.id) ?? ol),
            order: ol.order,
            showInBar: ol.showInBar,
        }));

        return {
            ...state,
            quickFilters: {
                ...(state.quickFilters ?? getDefaultQuickFiltersList()),
                [props.homeCommunityIndex]: updatedList,
            },
        };
    }),

    on(CommunityApiActions.initializeQuickFiltersSuccess, (state, props) => {
        return {
            ...state,
            quickFilters: {
                ...(state.quickFilters ?? getDefaultQuickFiltersList()),
                [props.homeCommunityIndex]: [
                    ...props.quickFilters[props.homeCommunityIndex],
                ],
            },
        };
    }),
);
